import React, { createContext, useState, useEffect } from 'react'
export const contentTypesArr = [ "IMAGES","AUDIO","FILES","VIDEOS"]


export const AssetContext = createContext({
  folderTree: [],
  setFolderTree: () => {},
  contentType: '',
  setContentType: () => {},
  catalogId: '',
  setCatalogId: ()=>{},
  plpSlug: '',
  setPlpSlug: ()=>{},
})

const AssetContextProvider = (props) => {

  const [folderTree, setFolderTree] = useState()
  const [contentType,setContentType] = useState(contentTypesArr[0])
  const [catalogId,setCatalogId] = useState('')
  const [plpSlug,setPlpSlug] = useState('')

  
  return (
    <AssetContext.Provider
      value={{
        folderTree,
        setFolderTree,
        contentType,
        setContentType,
        catalogId,
        setCatalogId,
        plpSlug,
        setPlpSlug,
      }}
    >
      {props.children}
    </AssetContext.Provider>
  )
}

export default AssetContextProvider