import ApiRoot from './fetcher'
import apis from '@/framework/config/api-config'


export const getDataList = async (params: any): Promise<any> => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().applicationFindPage(params)
    return res || {}
  } catch (e) {
    return false
  }
}
export const deleteData = async (params: any): Promise<boolean> => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().applicationDelete(params)
    return res || false
  } catch (e) {
    return false
  }
}

export const updateStatus = async (parmars: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().applicationModify(parmars)
    return res || false
  } catch (e) {
    return false
  }
}

export const create = async (parmars: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().applicationCreate(parmars)
    return res || false
  } catch (e) {
    return false
  }
}


export const applicationSecretCreate = async (parmars: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().applicationSecretCreate(parmars)
    return res || false
  } catch (e) {
    return false
  }
}

export const applicationSecretFindPage = async (parmars: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().applicationSecretFindPage(parmars)
    return res || false
  } catch (e) {
    return false
  }
}


export const applicationSecretDelete = async (parmars: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().applicationSecretDelete(parmars)
    return res || false
  } catch (e) {
    return false
  }
}
export const findAuthUserPageByApp = async (parmars: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().findAuthUserPageByApp(parmars)
    return res || {}
  } catch (e) {
    return {}
  }
}

export const findAuthUserPage = async (parmars: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().findAuthUserPage(parmars)
    return res || {}
  } catch (e) {
    return {}
  }
}


export const authUserCreate = async (parmars: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().authUserCreate(parmars)
    return res || {}
  } catch (e) {
    return {}
  }
}
export const authUserAppBind = async (parmars: any, query: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().authUserAppBind(parmars, query)
    return res || {}
  } catch (e) {
    return {}
  }
}
export const queryUserAccountPage = async (input: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().queryUserAccountPage(input)
    return res || {}
  } catch (e) {
    return {}
  }
}
export const authSendMessage = async (input: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().authSendMessage(input)
    return res || false
  } catch (e) {
    return false
  }
}
export const authActivityLogFindPage = async (input: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().authActivityLogFindPage(input)
    return res || {}
  } catch (e) {
    return {}
  }
}
export const userAccountCreate = async (input: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().userAccountCreate(input)
    return res || {}
  } catch (e) {
    return {}
  }
}
export const userAccountDelete = async (input: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().userAccountDelete(input)
    return res || false
  } catch (e) {
    return false
  }
}



export const userSessionFind = async (input: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().userSessionFind(input)
    return res || []
  } catch (e) {
    return []
  }
}

export const userSessionDelete = async (input: any, input1: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().userSessionDelete(input, input1)
    return res || false
  } catch (e) {
    return false
  }
}

export const findAppPageByUser = async (input: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().findAppPageByUser(input)
    console.log(res)
    return res || {}
  } catch (e) {
    return {}
  }
}

export const authUserUpdate = async (input: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().authUserUpdate(input)
    return res || {}
  } catch (e) {
    return {}
  }
}
export const getAuthUser = async (input: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().getAuthUser(input)
    return res || {}
  } catch (e) {
    return {}
  }
}
export const authResourceListByApp = async (input: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().authResourceListByApp(input)
    console.log(res)
    return res || []
  } catch (e) {
    console.log(e)
    return []
  }
}
export const roleCreate = async (input: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().roleCreate(input)
    console.log(res)
    return res || []
  } catch (e) {
    console.log(e)
    return []
  }
}
export const findRolePage = async (input: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().findRolePage(input)
    console.log(res)
    return res || []
  } catch (e) {
    console.log(e)
    return []
  }
}


export const roleDelete = async (input: any, appId: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().roleDelete(input, appId)
    console.log(res)
    return res || false
  } catch (e) {
    console.log(e)
    return false
  }
}


export const getRole = async (input: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().getRole(input)
    console.log(res)
    return res || []
  } catch (e) {
    console.log(e)
    return []
  }
}

export const roleUpdate = async (input: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().roleUpdate(input)
    console.log(res)
    return res || false
  } catch (e) {
    console.log(e)
    return false
  }
}



export const userRoleBind = async (a: any, u: any, r: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().userRoleBind(a, u, r)
    console.log(res)
    return res || false
  } catch (e) {
    console.log(e)
    return false
  }
}

export const userInfoByToken = async (input: any) => {
  try {
    const data = await ApiRoot({ url: `${process.env.REACT_APP_AUTHPATH}/graphql` }).users().userAttestation(input)
    return data
  } catch (e) {
    return false
  }
}


export const applicationUpdate = async (a: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().applicationUpdate(a)
    console.log(res)
    return res || false
  } catch (e) {
    console.log(e)
    return false
  }
}


export const applicationGet = async (a: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().applicationGet(a)
    console.log(res)
    return res || false
  } catch (e) {
    console.log(e)
    return false
  }
}

export const deleteAppUserRel = async (a: any) => {
  try {
    let res = await ApiRoot({ url: apis?.appclents }).users().deleteAppUserRel(a)
    console.log(res)
    return res || false
  } catch (e) {
    console.log(e)
    return false
  }
}








