/*
 * @Author: yuszhou
 * @Date: 2023-01-30 13:47:02
 * @LastEditors: yuszhou@deloitte.com yuszhou@deloitte.com.cn
 * @LastEditTime: 2023-05-08 17:20:10
 * @FilePath: \rc_china_storeportal\src\components\common\Wangeditor\addstyle.ts
 * @Description: 给Vdom设置letterSpacing 属性
 */
import { h, VNode, VNodeStyle } from 'snabbdom'
import { SlateElement, SlateText, SlateDescendant } from '@wangeditor/editor'
function addVnodeStyle(vnode: VNode, newStyle: VNodeStyle) {
  if (vnode.data == null) vnode.data = {}
  const data = vnode.data
  if (data.style == null) data.style = {}
  Object.assign(data.style, newStyle)
}
export default function renderStyle(node: SlateDescendant, vnode: VNode): VNode {
  // @ts-ignore
  const { letterSpacing, margin } = node as SlateElement
  let newVnode = vnode
  // 2. 为 vnode 添加样式标签
  if (letterSpacing) {
    addVnodeStyle(newVnode, { letterSpacing })
  }
  if (margin) {
    addVnodeStyle(newVnode, { margin })
  }
  return newVnode
}