import React, { createContext, useState } from 'react';

export const PreviewContext = createContext();


const PreviewContextProvider = (props) => {
  const [thumbnail, setThumbnail] = useState('')
  const [description,setDescription] = useState('')
  const [imgUrl, setImgUrl] = useState('')

  const clearPreviewContext = () => {
    setDescription('')
    setImgUrl('')
  }


  return (
    <PreviewContext.Provider value={{ thumbnail, setThumbnail,description,setDescription,imgUrl, setImgUrl,clearPreviewContext }}>
      {props.children}
    </PreviewContext.Provider>
  );
}

export default PreviewContextProvider;