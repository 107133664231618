/*
 * @Author: yuszhou@deloitte.com
 * @Date: 2023-01-17 14:54:52
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-02-01 15:00:12
 * @FilePath: \rc_china_storeportal\src\components\common\Wangeditor\latterSpace.tsx
 * @Description: 给editor添加一个letter-spacing的属性
 */

import { IDomEditor, IOption, ISelectMenu } from '@wangeditor/core'
import { SlateEditor, SlateElement, SlateNode, SlateTransforms } from '@wangeditor/editor'
//spaceing list
const selectOptions: IOption[] = [
  { text: '0.5px', value: '0.5px' },
  { text: '1px', value: '1px' },
  { text: '1.5px', value: '1.5px' },
  { text: '2px', value: '2px' },
  { text: '2.5px', value: '2.5px' },
  { text: '3px', value: '3px' },
  { text: '0.125em', value: '0.125em' },
  { text: '0.2em', value: '0.2em' },
  { text: '0.25em', value: '0.25em' },
  { text: '0.3em', value: '0.3em' },
]
export default class LatterSpaceing implements ISelectMenu {
  constructor() {
    this.title = 'LatterSpacing';
    this.tag = 'select';
    this.iconSvg = '<svg t="1675047560747" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2076" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16"><path d="M298.666667 725.333333h426.666666v-55.168a21.333333 21.333333 0 0 1 36.437334-15.061333l94.805333 94.805333a25.6 25.6 0 0 1 0 36.181334l-94.805333 94.805333a21.333333 21.333333 0 0 1-36.437334-15.061333V810.666667H298.666667v55.168a21.333333 21.333333 0 0 1-36.437334 15.061333l-94.805333-94.805333a25.6 25.6 0 0 1 0-36.181334l94.805333-94.805333a21.333333 21.333333 0 0 1 36.437334 15.061333V725.333333z m256-469.333333v341.333333a42.666667 42.666667 0 0 1-85.333334 0V256H256a42.666667 42.666667 0 1 1 0-85.333333h512a42.666667 42.666667 0 0 1 0 85.333333h-213.333333z" fill="#5D6E7F" p-id="2077"></path></svg>'
  }
  selectPanelWidth?: number | undefined;
  title: string;
  iconSvg?: string | undefined;
  hotkey?: string | undefined;
  alwaysEnable?: boolean | undefined;
  tag: string;
  width?: number | undefined;
  getOptions(editor: IDomEditor): IOption[] {
    return selectOptions
  };
  getValue(editor: IDomEditor): string | boolean {
    return editor.getHtml()
  }
  isActive(editor: IDomEditor): boolean {
    return false
  }
  isDisabled(editor: IDomEditor): boolean {
    return false
  }
  exec(editor: IDomEditor, value: string | boolean) {
    if (this.isDisabled(editor)) return
    SlateTransforms.setNodes(editor, {
      // @ts-ignore
      letterSpacing: value
    }, {
      mode: 'highest'
    })
    const nodeEntries = SlateEditor.nodes(editor, {
      match: (node: SlateNode) => {  // TS syntax
        if (SlateElement.isElement(node)) {
          return true
        }
        return false
      },
      universal: true,
    })

    if (nodeEntries == null) {
      // console.log('当前未选中的 paragraph')
    } else {
      for (let nodeEntry of nodeEntries) {
        const [node, path] = nodeEntry
        SlateTransforms.removeNodes(editor)
        // console.log('================there is your node================')
        // console.log(node)
        editor.insertNode(node)
      }
    }
  }
}