/*
 * @Author: yuszhou
 * @Date: 2023-02-01 15:06:50
 * @LastEditors: yuszhou@deloitte.com yuszhou@deloitte.com.cn
 * @FilePath: \rc_china_storeportal\src\components\common\Wangeditor\parsetStyleToEditor.ts
 * @Description: 自定义css样式反显到editor
 */
import { Boot, SlateDescendant, SlateText } from '@wangeditor/editor'
function _getStyleValue(text: any, value: any): string {
  if (!text) return ''
  if (/letter-spacing/g.test(text.outerHTML)) {
    return text.outerHTML.match(/letter-spacing:.*?;/)[0].replace(';', '').split(':')[1]
  } else if (/margin/g.test(text.outerHTML)) {
    return text.outerHTML.match(/margin:.*?;/)[0].replace(';', '').split(':')[1]
  } else {
    return ''
  }
}
export default function parseStyleHtml($text: any, node: SlateDescendant): SlateDescendant {
  const textNode = node as SlateText
  const letterspacing = _getStyleValue($text, 'letter-spacing')
  const margin = _getStyleValue($text, 'margin')
  if (letterspacing) {
    //@ts-ignore
    textNode.letterSpacing = letterspacing
  }
  if (margin) {
    //@ts-ignore
    textNode.margin = margin
  }
  return textNode
}
