type TApiConfig = {
  auth: string
  test: string
  wx_account: string
  wx_fans: string
  wx_reply: string
  wx_qrcode: string
  wx_template: string
  wx_menu: string
  wx_banner: string
  wx_assets: string
  orderList: string
  orderDetail: string
  order_action: string
  voucher: string
  payment: string
  sc_product: string
  product: string
  product_es_list: string
  product_search: string
  sc_subscription: string
  com_subscription: string
  shop_category: string
  common_pet: string
  tag: string
  address_list: string
  consumer: string
  storeSetting: string
  liveStreaming: string
  aiRecoModel: string
  content: string
  blog: string
  webhook: string
  malts_product: string
  productTag: string
  productRibbon: string
  sale_category: string
  shipping_method: string
  attribute: string
  org: string,
  filter: string,
  region: string,
  uom: string
  tax: string
  points: string
  referral: string
  rewardSetting: string
  shippingMethod: string
  loyalty: string
  reward: string
  shop: string
  order_calculate_price: string
  create_order: string,
  promotions: string,
  login_register: string,
  appclents: string,
  warehouse: string,
  search: string,
  mini: string,
  cms_content: string,
  fulfillment: string,
  so: string,
  soFetch: string

}

interface IApiConfig {
  development: any
  production: any
  test?: TApiConfig
}
let BASE_URL_T = 'https://api.dev.d2cgo.com' //本地联调 redireact 单点调试
let BASE_URL = 'https://api.dev.d2cgo.com';
const APPENDEX_URL = `${process.env.REACT_APP_MAIN_VERSION}/graphql`;


if (process.env.REACT_APP_APP_ENV === 'prod') {
  BASE_URL = 'https://api.d2cgo.com';
  BASE_URL_T = 'https://api.d2cgo.com'
} else if (process.env.REACT_APP_APP_ENV === 'aws_prd') {
  BASE_URL = 'https://api-eu-west-2-aws.d2cgo.com';
  BASE_URL_T = 'https://api-eu-west-2-aws.d2cgo.com'
}

const API_CONFIG: TApiConfig = {
  // auth: `http://localhost:9003/graphql`,
  auth: `${BASE_URL_T}/comp/auth/${APPENDEX_URL}`,
  wx_account: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  wx_fans: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  wx_reply: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  wx_qrcode: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  wx_template: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  wx_menu: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  wx_banner: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  wx_assets: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  orderList: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  orderDetail: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  order_action: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  voucher: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  payment: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  sc_product: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  product: `${BASE_URL}/public/${APPENDEX_URL}`,
  product_es_list: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  product_search: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  sc_subscription: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  com_subscription: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  shop_category: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  common_pet: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  tag: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  address_list: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  consumer: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  storeSetting: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  // storeSetting: 'http://localhost:7000/fc-com-store-setting/${APPENDEX_URL}',
  liveStreaming: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  aiRecoModel: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  content: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  //content: `http://localhost:9003/graphql`,
  blog: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  webhook: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  malts_product: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  productTag: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  productRibbon: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  sale_category: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  rewardSetting: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  shipping_method: `${BASE_URL}/public/${APPENDEX_URL}`,
  attribute: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  org: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  filter: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  region: `${BASE_URL}/public/${APPENDEX_URL}`,
  // uom: "http://localhost:7000/fc-sc-uom/${APPENDEX_URL}/${APPENDEX_URL}",
  uom: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  tax: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  points: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  referral: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  shippingMethod: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  loyalty: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  reward: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  shop: `${BASE_URL}/shop/${APPENDEX_URL}`,
  order_calculate_price: `${BASE_URL}/shop/${APPENDEX_URL}`,
  create_order: `${BASE_URL}/shop/${APPENDEX_URL}`,
  promotions: `${BASE_URL}/seller-center/${APPENDEX_URL}`,
  login_register: `${BASE_URL}/shop/${APPENDEX_URL}`,
  // appclents: `http://localhost:9003/graphql`
  appclents: `${BASE_URL_T}/seller-center/${APPENDEX_URL}`,
  warehouse: `${BASE_URL}/composable/oms/${APPENDEX_URL}`,
  search: `${BASE_URL}/comp/search/${APPENDEX_URL}`,
  mini: `${BASE_URL}/shop/${APPENDEX_URL}`,
  cms_content: `${BASE_URL}/public/${APPENDEX_URL}`,
  fulfillment: `${BASE_URL}/comp/fulfillment/${APPENDEX_URL}`,
  so: `${BASE_URL}/comp/so/${APPENDEX_URL}`,
  soFetch: `${BASE_URL}/comp/so/${process.env.REACT_APP_MAIN_VERSION}`

}

export default API_CONFIG
