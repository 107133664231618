import React from 'react'
import Menus from '@/common/components/Menus'
import { cmsMenus } from "@/lib/cms-menus"

export const getMenuData = (activeTab: number) => {
  let menuData: any = cmsMenus
  return menuData
}
export default function MenusWrapper() {

  return (
    <Menus menus={cmsMenus} />
  )
}
