// 引入icon样式文件
// import "../assets/css/iconfont/iconfont"
import pagesIcon from '@/assets/images/cms-menu/pages.svg'
import postIcon from '@/assets/images/cms-menu/post.svg'
import settingIcon from '@/assets/images/cms-menu/setting.svg'
import collectionIcon from '@/assets/images/cms-menu/collection.svg'
import contentTypeIcon from '@/assets/images/cms-menu/contentType.svg'
import digitalAssetsIcon from '@/assets/images/cms-menu/digitalAssets.svg'
import quickStartICon from '@/assets/images/cms-menu/quickStart.svg'

export interface MenuObject {
  name: string
  value: string
  url: string
  key: string // for auth permission key
  icon?: any
  activekey: string // for menu item active
  langKey: string
  children?: MenuObject[]
}

export const cmsMenus: MenuObject[] = [
  // {
  //     name: 'Home',
  //     value: 'home',
  //     url: '/cms-home',
  //     key: 'home',
  //     icon: <span className="icon iconfont  icon-a-bianzu10" />,
  //     langKey: 'menu.cmsHome',
  //     // children: []
  // },
  {
    name: 'Quick Start',
    value: 'quickStart',
    url: '/cms-quick-start',
    key: "quickStart",
    activekey: 'cms-quick-start',
    icon: <span className='icon iconfont text-xl icon-quickstart' />,
    langKey: 'menu.quickStart',
  },
  {
    name: 'Search',
    value: 'search',
    url: '/cms-search',
    key: "search",
    activekey: 'cms-search',
    icon: <span className='icon iconfont text-xl icon-a-filesearch font-medium' />,
    langKey: 'menu.search',
  },
  {
    name: 'Themes',
    value: 'themes',
    url: '/cms-themes',
    key: "themes",
    activekey: 'cms-themes',
    icon: <span className='icon iconfont text-xl icon-Theme' />,
    langKey: 'Themes',
  },
  {
    name: 'Posts',
    value: 'posts',
    url: '',
    key: 'posts',
    activekey: 'cms-posts',
    icon: <span className='icon iconfont text-xl icon-posts' />,
    langKey: 'menu.cmsPosts',
    children: [
      {
        name: 'All posts',
        value: 'allPosts',
        url: '/cms-posts/post-list',
        activekey: 'post-list',
        key: 'posts.allPosts',
        langKey: 'menu.allPosts'
      },
      {
        name: 'Add New Posts',
        value: 'addNewPosts',
        url: '/cms-posts/post-add',
        activekey: 'post-add',
        key: 'posts.addNewPosts',
        langKey: 'menu.addNewPosts'
      },
      {
        name: 'Categories',
        value: 'Categories',
        url: '/cms-posts/category',
        activekey: 'category',
        key: 'posts.categories',
        langKey: 'menu.Categories'
      },
      {
        name: 'Tags',
        value: 'Tags',
        url: '/cms-posts/tag',
        activekey: 'tag',
        key: 'posts.tags',
        langKey: 'menu.Tags'
      },
    ]
  },
  {
    name: 'Pages',
    value: 'pages',
    url: '',
    key: 'pages',
    activekey: 'cms-pages',
    icon: <span className='icon iconfont text-xl icon-pages' />,
    langKey: 'menu.cmsPages',
    children: [
      {
        name: 'Page List',
        value: 'pageList',
        url: '/cms-pages/list',
        key: 'pages.pageList',
        activekey: 'cms-page-list',
        langKey: 'menu.cmsPages.pageList',
      },
      {
        name: 'Add New Page',
        value: 'addNewPage',
        url: '/cms-pages/detail',
        key: 'pages.addNewPage',
        activekey: 'cms-page-add-new',
        langKey: 'menu.cmsPages.addNewPage',
      }
    ]
  },
  {
    name: 'Collections',
    value: 'collections',
    url: '/cms-collections',
    activekey: 'cms-collections',
    key: 'collections',
    icon: <span className='icon iconfont text-xl icon-collections1' />,
    langKey: 'menu.cmsCollections',
    // children: []
  },
  {
    name: 'Content Types',
    value: 'contentTypes',
    url: '/cms-contentTypes',
    activekey: 'cms-contentTypes',
    key: 'contentTypes',
    icon: <span className='icon iconfont text-xl icon-contentype' />,
    langKey: 'menu.contentTypes',
    // children: []
  },
  {
    name: 'Storybook',
    value: 'storybook',
    url: 'https://brandsitekit.d2cgo.com',
    activekey: 'cms-storybook',
    key: 'storybook',
    icon: <span className='icon iconfont text-xl  icon-storybook' />,
    langKey: 'menu.Storybook',
    // children: []
  },

  // {
  //   name: 'mate Data',
  //   value: 'mateData',
  //   url: '',
  //   key: 'mateData',
  //   activekey: 'cms-mateData',
  //   icon: <span className='icon iconfont text-xl icon-datamanagement' />,
  //   langKey: 'menu.Metadata',
  //   children: [
  //     {
  //       name: 'Attributes',
  //       value: 'attributes',
  //       activekey: 'cms-attributes',
  //       url: '/cms-mateData/attributes',
  //       key: 'mateData.attributes',
  //       langKey: 'menu.attributes',
  //     },
  //   ]
  // },
  {
    name: 'Assets',
    value: 'assets',
    url: '',
    key: 'assets',
    activekey: 'cms-assets',
    icon: <span className='icon iconfont text-xl icon-assets' />,
    langKey: 'menu.cmsAsets',
    children: [
      {
        name: 'Media Library',
        value: 'mediaLibrary',
        url: '/cms-assets/media-library',
        activekey: 'cms-assets',
        key: 'assets.mediaLibrary',
        langKey: 'assets.mediaLibrary'
      },
    ]
  },
  // {
  //     name: 'Digital Assets',
  //     value: 'digitalAssets',
  //     url: '/cms-digitalAssets',
  //     key: 'digitalAssets',
  //     icon: <img src={digitalAssetsIcon} alt="" width={20} />,
  //     langKey: 'menu.digitalAssets',
  //     // children: []
  // },
  {
    name: 'Setting',
    value: 'setting',
    url: '',
    activekey: 'cms-setting',
    key: 'setting',
    icon: <span className='icon iconfont text-xl icon-setting' />,
    langKey: 'menu.setting',
    children: [
      {
        name: 'metaData',
        value: 'metaData',
        activekey: 'cms-setting-metaData',
        url: '/cms-setting/metaData',
        key: 'setting.metaData',
        langKey: 'menu.metaData',
      },
      {
        name: 'Webhook',
        value: 'webhook',
        url: '/cms-setting/webhook',
        activekey: 'cms-setting-webhook',
        key: 'setting.webhook',
        langKey: 'menu.setting.webhook',
      },
      {
        name: 'CDN',
        value: 'cdn',
        url: '/cms-setting/cdn',
        activekey: 'cms-setting-cdn',
        key: 'setting.cdn',
        langKey: 'menu.setting.cdn',
      },
      // {
      //   name: 'Privacy',
      //   value: 'privacy',
      //   url: '/cms-setting/privacy',
      //   activekey: 'cms-setting-privacy',
      //   key: 'setting.privacy',
      //   langKey: 'menu.setting.privacy',
      // },
    ]
  },
  // {
  //     name: 'Component',
  //     value: 'component',
  //     url: '/cms-component',
  //     key: 'component',
  //     icon: <span className="icon iconfont  icon-a-bianzu10" />,
  //     langKey: 'menu.component',
  //     // children: []
  // }
]

// export const initActive = (pathname: string) => {
//     let selectedKeys: string[] = []
//     menus.forEach((menu) => {
//         menu.children.forEach((subMenu) => {
//             if (subMenu.url === pathname) {
//                 selectedKeys = [menu.key, subMenu.key]
//             }
//         })
//     })
//     return selectedKeys
// }
