import { atom } from 'jotai'
import { TLangKey } from '@/locale'
import { getDataFormSeamCookie, getFormLocalstorage } from '@/utils/utils'
let defaultLocale: any = ''
// 初始化进入 cookie 将无法获取当前locale 只有在app中重新 setlang
try {
    defaultLocale = getDataFormSeamCookie("locale") as TLangKey;
} catch (error) {
    defaultLocale = null
}

export const langAtom = atom<TLangKey>(defaultLocale ?? 'en_US')
