import { IButtonMenu, IDomEditor } from "@wangeditor/editor"

export class ViewSourceMenu implements IButtonMenu {

  readonly title: string;
  readonly tag: string;
  isHtml: boolean;

  constructor() {
    this.title = 'Code';
    this.tag = 'button';
    this.isHtml = false;
  }

  getValue: (editor: IDomEditor) => string | boolean = (editor) => {
    return false;
  }

  isActive: (editor: IDomEditor) => boolean = (editor) => {
    return false;
  }

  isDisabled: (editor: IDomEditor) => boolean = (editor) => {
    return false;
  }

  exec: (editor: IDomEditor, value: string | boolean) => void = (editor, value) => {
    let _source = editor.getHtml();
    if (!this.isHtml) {
      _source = _source.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/ /g, "&nbsp;");
    } else {
      _source = editor.getText().replace(/&lt;/ig, "<").replace(/&gt;/ig, ">").replace(/&nbsp;/ig, " ");
    }
    // console.log("now source:", _source);
    this.isHtml = !this.isHtml;
    editor.setHtml(_source);
    // const $ele = document.createElement('textarea');
    // $ele.id = `source-editor-${Math.random().toString(36).slice(2)}`;
    // $ele.className = "fixed w-full h-[90%] left-0 bottom-0";
    // $ele.value = editor.getHtml();
    // document.body.appendChild($ele);
  }
}