/*
 * @Author: yuszhou
 * @Date: 2023-01-30 17:10:38
 * @LastEditors: yuszhou@deloitte.com yuszhou@deloitte.com.cn
 * @LastEditTime: 2023-05-08 17:32:29
 * @FilePath: \rc_china_storeportal\src\components\common\Wangeditor\styletohtml.ts
 * @Description: 将Vdom 中的属性添加到css样式中
 */
import { SlateElement, SlateDescendant } from '@wangeditor/editor'
function createNode(htmlStr: string) {
  var div = document.createElement("div");
  div.innerHTML = htmlStr;
  return div.childNodes[0];
}
function getInterHtml(dom: ChildNode): string {
  var div = document.createElement('div')
  div.appendChild(dom)
  return div.innerHTML
}
export default function styleToHtml(node: SlateDescendant, nodeHtml: string): string {
  // @ts-ignore
  const { letterSpacing, margin } = node as SlateElement
  const $elem = createNode(nodeHtml)
  if (letterSpacing) {
    // @ts-ignore
    $elem.style.letterSpacing = letterSpacing
  }
  if (margin) {
    // @ts-ignore
    $elem.style.margin = margin
  }
  return getInterHtml($elem)

}