import React, { lazy } from 'react' // 路由懒加载
const Layout = lazy(() => import('../common/components/Layout'))
// const Layout = lazy(() => import('../components/common/CmsLayout'))
const Home = lazy(() => import('../views/cms/home/index'))
const Login = lazy(() => import('../views/login'))
const QuickStart = lazy(() => import('../views/cms/quickStart/index'))
const Posts = lazy(() => import('../views/cms/posts/index'))
const Posts2 = lazy(() => import('../views/cms/posts/index2'))
const NewPosts = lazy(() => import('../views/cms/newPosts/list'))
const Pages = lazy(() => import('../views/cms/pages/index'))
const Content = lazy(() => import('../views/cms/content/index'))
const MediaLibraryList = lazy(() => import('../views/cms/assets/index'))
const MediaLibraryDetail = lazy(() => import('../views/cms/assets/detail'))
const ContentDetail = lazy(() => import('../views/cms/content/detail'))
const ContentTypes = lazy(() => import('../views/cms/contentTypes'))
const DigitalAssets = lazy(() => import('../views/cms/digitalAssets/index'))
const WebhookSetting = lazy(() => import('../views/setting/webhook'))
const ContentType = lazy(() => import('../views/cms/contentTypes/detail'))
const PageContent = lazy(() => import('@/views/cms-page/pageContent'))
const AttributesList = lazy(() => import('@/views/cms/attribute/list'))
const AddAttributes = lazy(() => import('@/views/cms/attribute/add'))
const AttributesDetail = lazy(() => import('@/views/cms/attribute/basicInfo'))
const PostDetail = lazy(() => import('@/views/cms/postDetail'))
const PostManage = lazy(() => import('@/views/cms/postManage/list'))
const TagList = lazy(() => import('@/views/cms/newPostManage/tagList'))
const CategoriesList = lazy(() => import('@/views/cms/newPostManage/categoriesList'))
const SelectStore = lazy(() => import('@/common/components/selectStore'))
const AuthLoading = lazy(() => import('@/views/authLoading'))
const CmsPage = lazy(() => import('@/views/cms-page'))
const Themes = lazy(() => import('@/views/cms-themes'))
const PageComponent = lazy(() => import('@/views/cms-page/pageContent/indexPage'))
const Search = lazy(() => import('@/views/search'))
const CDNSetting = lazy(() => import('@/views/setting/cdn'))
const PrivacySetting = lazy(() => import('@/views/setting/privacy'))

export interface RouteObject {
  caseSensitive?: boolean
  children?: RouteObject[]
  element?: React.ReactNode
  index?: boolean
  path?: string
  breadcrumbName?: string
  Navigate?: string
  key?: string
}

let routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <QuickStart /> },
      { path: 'cms-home', element: <Home /> },
      { path: 'cms-quick-start', element: <QuickStart /> },
      { path: 'cms-search', element: <Search /> },
      { path: 'cms-themes', element: <Themes />, breadcrumbName: 'Themes' },
      {
        path: 'cms-posts',
        breadcrumbName: 'Posts',
        children: [
          {
            index: true,
            element: <Posts2 />
          },
          {
            path: 'post-list',
            breadcrumbName: 'menu.allPosts',
            children: [
              {
                index: true,
                element: <Posts2 />
              },
              {
                path: 'post-detial/:slug/:locale/:id',
                breadcrumbName: 'Post Detail',
                element: <PostDetail />
              },
            ]
          },
          {
            path: 'post-list2',
            breadcrumbName: 'menu.allPosts',
            element: <Posts />,
          },
          {
            path: 'post-add',
            breadcrumbName: 'Add Post',
            element: <PostDetail key={Math.random()} />
          },
          {
            path: 'manage-category',
            breadcrumbName: 'menu.Categories',
            element: <PostManage />
          },
          {
            path: 'manage-tag',
            breadcrumbName: 'menu.Tags',
            element: <PostManage />
          },
          {
            path: 'category',
            breadcrumbName: 'Categories',
            element: <CategoriesList />
          },
          {
            path: 'tag',
            breadcrumbName: 'Tags',
            element: <TagList />
          },
        ]
      },
      {
        path: 'cms-pages',
        breadcrumbName: 'Pages',
        children: [
          {
            index: true,
            element: <Content />,
            breadcrumbName: 'Pages',
          },
          {
            path: 'list',
            element: <Content />,
            breadcrumbName: 'Page List',
            children: [
              {
                index: true,
                element: <Content />,
                breadcrumbName: 'Pages',
              },
              {
                path: 'pageTypes',
                element: <ContentDetail />,
                breadcrumbName: 'New Page',
              },
            ]
          },

          {
            path: 'detail',
            element: <CmsPage />,
            breadcrumbName: 'Pages',
          },
          {
            path: 'detailNew',
            element: <PageComponent />,
            breadcrumbName: 'PageComponent',
          },
          {
            path: 'carousel',
            element: <PageContent />,
            breadcrumbName: 'PageContent',
          },
        ]
      },
      {
        path: 'cms-collections',
        breadcrumbName: 'Collections',
        children: [
          {
            index: true,
            element: <Content />,
            breadcrumbName: 'Collections',
          },
          {
            path: 'collection',
            element: <ContentDetail />,
            breadcrumbName: 'Collections',
          },
        ]
      },
      {
        path: 'cms-contentTypes',
        breadcrumbName: 'Content Types',
        children: [
          {
            index: true,
            element: <ContentTypes />,
            breadcrumbName: 'Content Types',
          },
          { path: 'contentTypes-component', element: <ContentType />, breadcrumbName: 'Content Types' },
          { path: 'contentTypes-collection', element: <ContentType />, breadcrumbName: 'Content Types' },
          { path: 'contentTypes-pageTypes', element: <ContentType />, breadcrumbName: 'Content Types' },
        ]
      },
      // {
      //   path: 'cms-mateData',
      //   breadcrumbName: 'menu.Metadata',
      //   children: [
      //     { index: true, element: <AttributesList /> },
      //     {
      //       path: 'attributes', breadcrumbName: 'Attributes',
      //       children: [{
      //         index: true, element: <AttributesList />
      //       }, {
      //         path: 'add', element: <AddAttributes />, breadcrumbName: 'Add Attributes'
      //       },
      //       {
      //         path: 'base-info', element: <AttributesDetail />, breadcrumbName: 'Attributes Basic Info'
      //       }
      //       ]
      //     },
      //   ]
      // },
      {
        path: 'cms-assets',
        breadcrumbName: 'menu.cmsAsets',
        children: [
          {
            index: true,
            element: <MediaLibraryList />,
            breadcrumbName: 'assets.mediaLibrary',
          },
          {
            path: 'media-library', breadcrumbName: 'assets.mediaLibrary',
            children: [{
              index: true, element: <MediaLibraryList />
            },
            {
              path: 'edit',
              element: <MediaLibraryDetail />,
              breadcrumbName: 'assets.mediaLibrary edit',
            }
            ]
          }
        ]
      },
      // { path: 'contentTypes-component', element: <ContentType />, breadcrumbName: 'Content Types' },

      { path: 'cms-digitalAssets', element: <DigitalAssets /> },
      {
        path: 'cms-setting', breadcrumbName: 'menu.setting', children: [
          {
            index: true,
            element: <WebhookSetting />,
            breadcrumbName: 'menu.setting.webhook',
          },
          {
            path: 'metaData',
            breadcrumbName: 'menu.Metadata',
            children: [
              { index: true, element: <AttributesList /> },
              {
                path: 'add', element: <AddAttributes />, breadcrumbName: 'Add Attributes'
              },
              {
                path: 'base-info', element: <AttributesDetail />, breadcrumbName: 'Attributes Basic Info'
              }
            ]
          },
          {
            path: 'webhook', breadcrumbName: 'menu.setting.webhook', element: <WebhookSetting />
          },
          {
            path: 'cdn',
            element: <CDNSetting />,
            breadcrumbName: 'menu.setting.cdn',
          },
          {
            path: 'privacy',
            element: <PrivacySetting />,
            breadcrumbName: 'menu.setting.privacy',
          }
        ]
      },
      // { path: '/login', element: <Login />, key: 'store' },
    ],
    key: 'cms'
  },
  //{ path: '*', element: <Home />, key: 'cms' },
  { path: '/authloading', element: <AuthLoading />, key: 'store' },
  { path: '/store/select', element: <SelectStore />, key: 'store' },
  { path: '*', element: <Login />, key: 'store' },
]

// The useRoutes() hook allows you to define your routes as JavaScript objects
// instead of <Routes> and <Route> elements. This is really just a style
// preference for those who prefer to not use JSX for their routes config.

export default routes
